<template>
  <div
    class="integralRecord"
    :style="!isWeixin_status ? 'padding-top: 50px' : ''"
  >
    <NavBar
      fixed
      v-if="!isWeixin_status"
      right-text="兑换"
      title="积分记录"
      @click-left="goBack"
      @click-right="() => this.$router.push('/integralshop')"
      :left-arrow="isWeixin_status == false"
    >
    </NavBar>
    <List
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="getPointLogs"
    >
      <template v-for="(item, index) in listArr">
        <Cell
          :key="index"
          :value="item.style | update_type"
          :label="item.create_time"
        >
          <template #title>
            <span class="custom-title">{{ item.type == 1 ? "+" : "-" }}</span>
            <span class="custom-title">{{ item.num }}</span>
          </template>
        </Cell>
      </template>
    </List>
    <Empty v-if='listArr.length === 0' description="暂无数据"></Empty>
    
  </div>
</template>
<script>
import { NavBar, List, Cell, Empty } from "vant";
import UserInfo from "@/api/user";
export default {
  components: {
    NavBar,
    List,
    Cell,
    Empty
  },
  filters: {
    // 类型 1线下买单 2充值 3后台变动 4回退 5其他 6积分兑换 7邀请新人
    update_type: function (val) {
      if (val == 1) return "线下买单";
      if (val == 2) return "充值";
      if (val == 3) return "后台变动";
      if (val == 4) return "回退";
      if (val == 5) return "其他";
      if (val == 6) return "积分兑换";
      if (val == 7) return "邀请新人";
    },
  },
  data() {
    return {
      loading: false,
      finished: false,
      listArr: [],
      show: false,
      details: {},
      page: 0,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async getPointLogs() {
      try {
        const res = await UserInfo.pointLogs(++this.page);
        if (res.code == 200) {
          if (res.data.length > 0) {
            this.listArr = [...this.listArr, ...res.data];
            this.loading = false;
            if (res.data.length == 0) {
              this.finished = true;
            }
          }
        } else {
          this.loading = false;
          this.finished = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.integralRecord {
  width: 100%;
  height: 100vh;
  background: rgba(244, 244, 244, 1);
  overflow-y: scroll;
  overflow-x: hidden;
  .custom-title {
    font-size: 16px;
    font-weight: 560;
  }
}
</style>
